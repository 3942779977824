<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col
          style="margin-bottom: 20px"

          xl="4"
          md="4"
          lg="4"
          sm="12"
      >
        <div align="right">
          <h4>Filtros:
          </h4>
          <div style="display: flex; justify-content: end">
            <b-form-radio
                v-model="selectFilter"
                style="margin-right: 20px"
                value="name"
            >
              Nombre
            </b-form-radio>
            <b-form-radio
                v-model="selectFilter"
                value="phone"
            >
              Celular
            </b-form-radio>
          </div>
        </div>

      </b-col>
      <b-col
          xl="12"
          md="12"
          lg="12"
          sm="12"
      >
      </b-col>
      <b-col
          xl="4"
          md="4"
          lg="4"
          sm="12"
      >
        <b-input-group>
          <b-form-input placeholder="Buscar Paciente" v-model="name"/>
        </b-input-group>
      </b-col>
      <b-col

          xl="4"
          md="4"
          lg="4"
          sm="12"
      >

        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="searchPatient()"
        >
          Buscar
        </b-button>

      </b-col>
      <b-col

          xl="4"
          md="4"
          lg="4"
          sm="12"
      >
        <div align="right">
          <router-link :to="{name:'AddPatient'}">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
            >
              Agregar Paciente
            </b-button>
          </router-link>
        </div>


      </b-col>
    </b-row>
    <b-row class="match-height mt-3" v-if="array_patient.length > 0">
      <b-col
          v-for="(a, index) in array_patient" :key="index"
          xl="4"
          md="4"
          lg="4"
          sm="12"
          style="cursor: pointer"
      >
        <router-link :to="{name: 'Patient', params:{id:a.id}}">
          <b-card
              :header="a.patient_file_number"
              :title="a.name"
              class="text-center"
              footer-class="text-muted"
          >
            <b-card-text>
              <b-avatar
                  size="70px"
                  variant="light-primary"
                  :text="a.name_e"
              />
            </b-card-text>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
            >
              Ver Paciente
            </b-button>
          </b-card>
        </router-link>
      </b-col>

    </b-row>

  </section>

</template>

<script>
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar, BFormRadio
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import {kFormatter} from '@core/utils/filter'
import {mapActions} from "vuex/dist/vuex.esm.browser";
import Ripple from 'vue-ripple-directive'

export default {
  name: "PatientList",
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormRadio
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      array_patient: [],
      name: '',
      selectFilter: 'name',
    }
  },
  methods: {
    ...mapActions('patient', ['findPatients', 'findPatientsSearch','findPatientsSearchType']),
    async patientsAll() {
      this.array_patient = []
      const response = await this.findPatients();
      for (const a of response) {
        this.array_patient.push({
          patient_file_number: a.patient_file_number,
          name: a.name,
          id: a.id,
          name_e: a.name.slice(0, 2)
        })
      }
    },
    async searchPatient() {
      this.array_patient = []
      if (this.name === '') {

        await this.patientsAll()
        return
      }
      this.array_patient = []
      const response = await this.findPatientsSearchType({search:this.name, type:this.selectFilter});
      this.array_patient = []
      for (const a of response) {
        this.array_patient.push({
          patient_file_number: a.patient_file_number,
          name: a.name,
          id: a.id,
          name_e: a.name.slice(0, 2)
        })
      }

    }
  },
  async created() {
    await this.patientsAll()
  }


}
</script>

<style scoped>

</style>

